<template>
  <div class="header">
    <div class="logoAll">
      <img :src="logo" @click="toHome" class="logo" alt="" />
    </div>

    <div class="menu" v-if="menuList.length">
      <p
        v-for="(item, index) in menuList"
        :key="item.id"
        @click="menuChange(item, index)"
        :class="{ active: navIndex == index }"
      >
        {{ item.name }}
      </p>
    </div>
    <div class="tuoyuSearch" :style="{ width: menuList.length ? '337px' : '457px' }">
      <input
        v-model="keyWords"
        @keyup.enter="goSearch()"
        placeholder="请输入您需要搜索的课程"
      />
      <div class="btn" @click="goSearch">搜索</div>
    </div>
    <p style="flex: 1"></p>
    <div class="login" v-if="!isLogin && $route.params.plat_type == 'train'">
      <div @click="(type = 'login'), $store.commit('changeLoginShow', true)">登录</div>
      <div class="login-btn-divider"></div>
      <div
        @click="
          (type = plat == '373' ? 'register' : 'login'),
            $store.commit('changeLoginShow', true)
        "
      >
        注册
      </div>
    </div>

    <van-popover v-model="showPopover" trigger="click" overlay>
      <div class="popover">
        <ul>
          <li
            v-for="(item, index) in centerList"
            :key="index"
            @click="jumpList(item.url)"
          >
            <img :src="item.img" />
            <p>{{ item.text }}</p>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>

        <p @click="logout" class="btn">退出登录</p>
      </div>
      <template #reference>
        <div class="user-info" v-if="isLogin && $route.params.plat_type == 'train'">
          <van-image round fit="cover" lazy-load @error="error"></van-image>
          <img
            style="
              width: 34px;
              height: 34px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;
              border: 1px solid #fff;
            "
            :src="
              (myUser && myUser.userface) ||
              'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/userLogo.jpg'
            "
            alt=""
          />
          <span style="margin-left: 10px; color: #333">{{
            myUser ? myUser.name || myUser.mobile : "用户昵称"
          }}</span>
        </div>
      </template>
    </van-popover>

    <van-popup
      overlay-class="myOverlay"
      v-model="show"
      get-container="body"
      @click-overlay="$store.commit('changeLoginShow', false)"
    >
      <div class="login-main">
        <div class="login-title">
          {{ type == "login" ? "验证码登录" : "注册" }}
        </div>
        <div v-show="type == 'login'">
          <div class="phone-number">
            <van-field v-model="phone" center clearable placeholder="请输入手机号">
            </van-field>
          </div>
          <div class="auth-code">
            <van-field v-model="sms" center placeholder="请输入验证码">
              <template #button>
                <div
                  class="code-btn"
                  @click="sendSms"
                  :class="smsNum == 60 ? '' : 'no_touch'"
                >
                  {{ smsNum == 60 ? smsText : smsNum + "s后重试" }}
                </div>
              </template>
            </van-field>
          </div>
          <van-button color="#0080d6" class="login-btn" @click="login">{{
            type == "login" ? "登录" : "注册"
          }}</van-button>
          <p
            v-if="plat == '373'"
            style="
              text-align: center;
              font-size: 14px;
              padding-top: 10px;
              color: #2e66d0;
              cursor: pointer;
            "
            @click="type = 'register'"
          >
            注册账号
          </p>
          <div class="agreement">
            <p>
              <van-checkbox
                icon-size="14px"
                v-model="checked"
                checked-color="#0080d6"
                shape="square"
              >
                我同意并愿意接受</van-checkbox
              >
            </p>
            <p><span>《用户服务协议》</span>和<span>《隐私政策》</span></p>
          </div>
        </div>
        <div class="formRegister" v-show="type == 'register'">
          <div class="line">
            <p>姓名</p>
            <span>:</span>
            <div><input v-model="form.name" placeholder="请输入您的姓名" /></div>
          </div>
          <div class="line">
            <p>性别</p>
            <span>:</span>
            <div>
              <el-radio v-model="form.gender" :label="1">男</el-radio>
              <el-radio v-model="form.gender" :label="2">女</el-radio>
            </div>
          </div>
          <div class="line">
            <p>省市区</p>
            <span>:</span>
            <div>
              <myArea @update="changeAdd" />
            </div>
          </div>
          <div class="line">
            <p>所在单位</p>
            <span>:</span>
            <div><input v-model="form.remark" placeholder="请输入您的单位名称" /></div>
          </div>
          <div class="line">
            <p>手机号</p>
            <span>:</span>
            <div><input v-model="phone" placeholder="请输入您的手机号" /></div>
          </div>
          <div class="line">
            <p>验证码</p>
            <span>:</span>
            <div>
              <div
                class="code-btn"
                @click="sendSms"
                :class="smsNum == 60 ? '' : 'no_touch'"
              >
                {{ smsNum == 60 ? smsText : smsNum + "s后重试" }}
              </div>
              <input v-model="sms" placeholder="请输入验证码" />
            </div>
          </div>

          <div class="btn" @click="login">注册</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import myArea from "@/components/area.vue";
export default {
  components: { myArea },
  data: function () {
    return {
      form: {
        gender: 1,
        name: "",
        province_id: "",
        province_name: "",
        city_id: "",
        city_name: "",
        district_id: "",
        district_name: "",
        remark: "",
      },
      type: "login",
      pw1: true,
      pw2: true,
      password: "",
      password2: "",
      keyWords: "",
      navIndex: 0,
      menuList: [],
      centerList: [
        {
          text: "个人中心",
          img:
            "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/center.png",
          url: "myCenter",
        },
        {
          text: "我的证书",
          img:
            "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/cert.png",
          url: "certList",
        },
        {
          text: "学习记录",
          img:
            "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/record.png",
          url: "study",
        },
        {
          text: "证书查询",
          img:
            "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83-search.png",
          url: "certForm",
        },
      ],
      activeColor: "#a40001",
      activeBg: "#a40001",
      hidden: false,
      vanSet: false,
      keyword: "",
      phone: "",
      sms: "",
      user: "",
      checked: true,
      smsLoading: false,
      smsTimer: "",
      smsNum: 60,
      smsText: "获取验证码",
      showPopover: false,
      actions: [
        {
          text: "退出登录",
        },
      ],
      loading: null,
    };
  },
  mounted() {
    this.getCat();
    this.user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    this.activeColor = this.active == "molecule" ? "#a40001" : "";
    this.activeBg = this.active == "molecule" ? "#a40001" : "#fff";

    if (this.plat == 379) {
      this.centerList = [
        {
          text: "个人中心",
          img:
            "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/center.png",
          url: "myCenter",
        },
      ];
    }
  },
  methods: {
    async getCat() {
      let res = null;
      if (this.$store.state.plat_id == "373") {
        res = await this.$api({
          method: "get",
          url: "/api/index?flat_id=" + this.plat,
        });
      } else {
        res = await this.$request.jiazhangIndex();
      }

      this.menuList = res.data.parent_list.map((item) => {
        return { ...item, url: "lessonTuoyu" };
      });
      let r = this.$route;
      let activeName = "";
      if (this.$route.query.id && !this.$route.path.includes("lessonYingyouZT")) {
        activeName = this.menuList.findIndex(
          (item) => item.id && item.id == this.$route.query.id
        );
      } else if (this.$route.path.includes("lessonYingyouZT")) {
        activeName = this.menuList.findIndex((item) => item.id && item.id == 1);
      } else {
        activeName = this.menuList.findIndex(
          (item) => item.url && item.url.indexOf(this.$route.path.split("/").pop()) != -1
        );
      }
      this.navIndex = activeName;
    },
    changeAdd(info) {
      console.log(info);
      this.form.province_id = info[0].id;
      this.form.province_name = info[0].name;
      this.form.city_id = info[1].id;
      this.form.city_name = info[1].name;
      this.form.district_id = info[2].id;
      this.form.district_name = info[2].name;
    },
    toHome() {
      let url = "./index";
      console.log(this.$until);
      this.$router.push(url);
      // this.$until.toPageOpen(url);
    },
    /**取cookie */
    getCookie(objName) {
      //获取指定名称的cookie的值
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == objName) return unescape(temp[1]); //解码
      }
      return "";
    },
    error() {
      this.user.userface =
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/pad/amfababy_assess/avatar.png";
    },
    logout() {
      this.$api({
        url: "/api/auth/logout",
        method: "post",
      }).then((res) => {
        if (res.code == 200) {
          this.showPopover = false;
          localStorage.removeItem("user");
          this.$store.commit("changeLoginShow", false);
          console.log("退出登录");
          this.$store.commit("SET_LOGINSTATUS", false);
          this.$store.commit("SET_USER", null);
        }
      });
    },
    login() {
      this.phone = this.phone.trim();
      if (this.$until.checkPhone(this.phone) != "ok") {
        this.$toast(this.$until.checkPhone(this.phone));
        return;
      }
      if (!this.sms) {
        this.$toast("请输入验证码");
        return;
      }

      let data = {
        mobile: this.phone,
        code: this.sms,
      };
      if (this.type == "register") {
        if (!this.form.name) {
          this.$toast("请输入姓名");
          return;
        }
        if (!this.form.province_id) {
          this.$toast("请选择省市区");
          return;
        }
        if (!this.form.remark) {
          this.$toast("请输入所在单位");
          return;
        }
        data = { ...data, ...this.form };
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$api({
        url: `/api/auth/${this.type}`,
        method: "post",
        data,
      })
        .then((res) => {
          this.loading.close();
          if (res.code == 200) {
            this.$toast(res.msg);
            console.log(res);
            this.clearTimer();
            let { id, mobile, nickname, userface } = res.data.base;
            let { access_token } = res.data.token_list.original;
            this.user = res.data.base;
            this.user.access_token = access_token;
            localStorage.setItem("user", JSON.stringify(this.user));
            this.$store.commit("SET_USER", this.user);
            // this.setCookie('user', JSON.stringify(this.user))
            this.$store.commit("changeLoginShow", false);
            this.$store.commit("SET_LOGINSTATUS", true);
            // this.$store.dispatch("getStudy");
            // this.$store.dispatch("getVIPInfo");
            location.reload();
          } else {
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading.close();
          console.log(err);
        });
    },
    /**存cookie */
    setCookie(objName, objValue, objHours) {
      var str = objName + "=" + escape(objValue); //编码
      if (objHours > 0) {
        //为0时不设定过期时间，浏览器关闭时cookie自动消失
        var date = new Date();
        var ms = objHours * 3600 * 1000;
        date.setTime(date.getTime() + ms);
        str += "; expires=" + date.toGMTString();
      }
      document.cookie = str;
    },
    sendSms() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.isPhoneNo(this.phone)) {
        this.$toast("手机号格式错误");
        return;
      }
      if (this.smsNum < 60) return;
      if (this.smsLoading) {
        return;
      }
      this.smsLoading = true;
      this.$api({
        url: "/api/sms",
        method: "POST",
        data: {
          mobile: this.phone,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$toast(res.msg);
            this.smsNum = this.smsNum - 1;
            this.smsTimer = setInterval(() => {
              this.smsNum = this.smsNum - 1;
              if (this.smsNum == 0) {
                this.smsNum = 60;
                this.clearTimer();
              }
            }, 1000);
          } else {
            this.$toast(res.msg);
          }
          this.smsLoading = false;
        })
        .catch((err) => {
          this.smsLoading = false;
          console.log(err);
        });
    },
    isPhoneNo(phone) {
      var pattern = /^1[3456789]\d{9}$/;
      return pattern.test(phone);
    },
    clearTimer() {
      if (this.smsTimer) {
        clearInterval(this.smsTimer);
        this.smsNum = 60;
      }
    },
    goSearch() {
      this.$until.toPageOpen("/search2?keywords=" + this.keyWords);
    },
    menuChange(item, index) {
      let url = "/" + this.$route.params.plat_type + "/" + item.url;
      if (item.id) {
        url = url + "?id=" + item.id;
      }
      this.$until.toPageOpen(url);
    },
    jumpList(name) {
      // console.log(window.location);

      // if(this.user){
      //   this.setCookie('user', JSON.stringify(this.user))
      // }
      // return
      if (name == "jiazhang03") {
        window.open(
          window.location.origin + window.location.pathname + "#/jiazhang03/index"
        );
        return false;
      } else if (name == "jiazhang06") {
        window.open(
          window.location.origin + window.location.pathname + "#/jiazhang06/index"
        );
        return false;
      } else {
        // console.log(name);
        this.$router.push(`./${name}`);
      }
      return true;
    },
  },
  computed: {
    myUser() {
      return this.$store.state.user;
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
    show() {
      return this.$store.state.isLoginShow;
    },
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    active: {
      get() {
        return this.$store.state.headerActive;
      },
      set(value) {
        return this.$store.headerActive;
      },
    },
    plat() {
      return this.$store.state.plat_id;
    },
    name() {
      return this.$store.state.plat.name;
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  watch: {
    showPopover(e) {
      // if (e) {
      //   this.$store.dispatch("getStudy");
      // }
    },
    $route(newval, oldval) {
      if (this.menuList.length) {
        this.navIndex = this.menuList.findIndex((item) => {
          return (
            item.url == newval.name &&
            (newval.query.id ? item.id == newval.query.id : true)
          );
        });
      }
      this.showPopover = false;
    },
  },
};
</script>
<style lang="scss">
$themeColor: #2e66d0;
.van-overlay {
  background-color: transparent !important;
}
.myOverlay {
  background-color: rgba($color: #000000, $alpha: 0.7) !important;
}
.logoAll {
  width: 230px;
  word-break: keep-all;
  padding-right: 20px;
  .logo {
    cursor: pointer;
    width: auto !important;
  }
}
.login-main {
  i {
    cursor: pointer;
  }
  .code-btn {
    color: $themeColor !important;
  }
  .agreement {
    p > span {
      color: $themeColor !important;
    }
  }
}

.user-info {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // height: 24px;
    width: 80px;
  }
}
.tuoyuSearch {
  height: 40px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    height: 40px;
    line-height: 40px;
    flex: 1;
    color: #999999;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 8px 0 0 8px;
    box-sizing: border-box;
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
  }
  .btn {
    border: 1px solid $themeColor;
    width: 80px;
    height: 40px;
    background: $themeColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;
    color: #fff;
  }
}
</style>
<style scoped lang="scss">
$themeColor: #2e66d0;
.formRegister {
  padding-left: 20px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .line {
    display: flex;
    align-items: center;
    width: 316px;
    margin-bottom: 35px;
    position: relative;
    .code-btn {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    > p {
      font-size: 16px;
      width: 68px;
      text-align: justify;
      text-align-last: justify;
      margin-right: 3px;
      position: relative;
      &::before {
        content: "*";
        color: #f00;
        position: absolute;
        left: -10px;
        top: 5px;
      }
    }
    > span {
      font-size: 16px;
    }
    > div {
      margin-left: 11px;
      padding-left: 10px;
      flex: 1;
      border-bottom: 1px solid #e4e8ed;
      line-height: 30px;
      height: 32px;
      display: flex;
      align-items: center;
      input {
        border: 0;
        height: 30px;
        background: transparent;
      }
      p {
        width: 79px;
        display: flex;
        align-items: center;
        color: #999999;
        cursor: pointer;
        img {
          margin-right: 8px;
        }
      }
      p.sexActive {
        color: #666;
      }
    }
  }
  .btn {
    background: #2e66d0;
    color: #fff;
    text-align: center;
    width: 327px;
    height: 46px;
    border-radius: 10px;
    line-height: 46px;
  }
}
.login {
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  color: $themeColor;
  font-size: 14px;
  border-radius: 8px;
  margin-left: auto;
  cursor: pointer;
  .login-btn-divider {
    background: $themeColor;
    width: 1px;
    height: 13px;
    margin: 0 5px;
  }
}
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  height: 36px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  color: #a40001;
  margin-left: 30px;
}
.header .menu {
  margin-right: 40px;
  width: 365px;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  p {
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      margin-right: 7px;
    }
  }
  p:hover,
  .active {
    color: $themeColor;
    font-weight: bold;
    background: none;
  }
}
.van-tab {
  font-size: 14px !important;
}
.popover {
  padding: 24px 0;
  font-size: 14px;
  width: 220px;
  ul {
    li {
      display: flex;
      padding: 5px 24px;
      height: 40px;
      line-height: 40px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #e4e4e4;
      }
      img {
        margin-right: 12px;
      }
      p {
        flex: 1;
        font-size: 16px;
      }
    }
  }
  .btn {
    border: 1px solid #e4e4e4;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
    width: 90%;
    margin: 20px auto 0;
    text-align: center;
    color: $themeColor;
    cursor: pointer;
    border-radius: 20px;
    &:hover {
      background: $themeColor;
      color: #fff;
    }
  }
}
</style>
